import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from './Header';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { useState } from "react";
import Mar1 from './images/usage/march/first.png';
import Mar2 from './images/usage/march/second.png';
import Mar3 from './images/usage/march/third.png';
import Mar4 from './images/usage/march/fourth.png';
import Mar5 from './images/usage/march/fifth.png';

import backgroundImage from './images/sonde_logo.png'; 

import April1 from './images/usage/april/first.png';
import April2 from './images/usage/april/second.png';
import April3 from './images/usage/april/third.png';
import April4 from './images/usage/april/fourth.png';
import April5 from './images/usage/april/fifth.png';

const imagesByMonth = {
    May: [
        Mar1, Mar2, Mar3, Mar4, Mar5
    ],
    April: [
        April1, April2, April3, April4, April5
    ],
    // Add more months and their corresponding images here
};

const Box = ({ text, link, classN }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(link);
    };

    return (
        <div className={classN} onClick={handleClick} style={{padding: '10px', border: '1px solid black', borderRadius: '5px' }}>
            {text}
        </div>
    );
};

class Usage extends React.Component {
    containerStyle = {
        padding: '1px',
        marginLeft: '1px'
    };

    monthSummaryStyle = {
        marginBottom: '10px',
        marginTop: '5px',
    };

    gridStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px',
    };

    topImageStyle = {
        gridColumn: 'span 2',
    };

    imageStyle = {
        width: '95%',
        height: 'auto',
    };

    headerStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'

    };

    rightContainerStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '38%', // Adjust this width as needed
    };

    constructor(props) {
        super(props);
        const defaultMonth = 'May'; // Set the default month here
        this.state = {
            selectedMonth: defaultMonth,
            images: imagesByMonth[defaultMonth] || [],
        };
    }

    handleMonthChange = (event) => {
        const month = event.target.value;
        this.setState({
            selectedMonth: month,
            images: imagesByMonth[month] || [],
        });
    };

    render() {
        const dropdownContainerStyle = {
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
        };
        
        const dropdownStyle = {
            height: '25px',
            margin: '2px',
            fontSize: '13px',
            backgroundColor: '#f8f9fa',
            border: '1px solid #ced4da',
            borderRadius: '5px',
            outline: 'none',
            
            transition: 'background-color 0.3s ease, transform 0.3s ease',
        };
        
        const dropdownLabelStyle = {
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#333',
            marginLeft: '10px',
        };

        
        

        return (
            <div >
                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <div style={{ ...this.headerStyle, flex: '1' }}>
                        <img src={backgroundImage} alt="Logo" style={{ width: '100px', height: '50px', margin: '10px' }} />
                        <Box text="Population Usage Analysis" classN="box_usage" />
                    </div>
                    <div style={{ ...this.rightContainerStyle, marginLeft: 'auto' }}>
                        <Box text="Usage Analysis" link="/enterprise-portal/usage_component" classN="box_usage_1" />
                        <Box text="Pop. Analysis" link="/enterprise-portal/vocal_component" classN="box_usage_1" />
                        <Box text="User Analysis" link="/enterprise-portal/vocal_usage_component" classN="box_usage_1" />
                    </div>
                </div>
                

                <div style={dropdownContainerStyle}>
                    <div style={this.monthSummaryStyle}>
                        <label htmlFor="month" style={dropdownLabelStyle}>Month Summary: </label>
                        <select 
                            id="month" 
                            value={this.state.selectedMonth} 
                            onChange={this.handleMonthChange} 
                            style={dropdownStyle}
                            
                        >
                            <option value="">Select Month</option>
                            <option value="May">May 2024</option>
                            <option value="April">April 2024</option>
                            {/* Add more options for other months */}
                        </select>
                    </div>
                </div>

                <div style={this.containerStyle}>
                    <div style={this.gridStyle}>
                        {this.state.images.map((image, index) => (
                            <div key={index} style={index === 0 ? this.topImageStyle : {}}>
                                <img src={image} alt={`Image for ${this.state.selectedMonth} ${index + 1}`} style={this.imageStyle} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Usage;
