import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import DashBoard from './DashBoard';
import Home from './Home';
import Enrollment from './Enrollment';
import Recorder from './Recorder';
import MentalFitness from './MentalFitness';
import RecordAudio from './RecordAudio';
import AdminPortal from './AdminPortal';
import ColorChangingBoxes from './ColorChanginBoxes';
import ColorManager from './ColorManager';
import Image1 from './images/Usage.jpg';
import Image2 from './images/vocal.jpg';
import Image3 from './images/vocal-usage.jpg';
import Usage from './Usage';
import Vocal from './Vocal';
import VocalUsage from './VocalUsage';
import DashPortalHeader from './DashPortalHeader';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/enterprise-portal" element={<AdminPortal />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/enrollment" element={<Enrollment />} />
        <Route path="/recorder" element={<Recorder />} />
        <Route path="/mental_fitness" element={<MentalFitness />} />
        <Route path="/test" element={<RecordAudio />} />
        <Route path="/colorBox" element={<ColorChangingBoxes />} />
        <Route path="/color" element={<ColorManager />} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path='/dash_portal_header' element={<DashPortalHeader/>}/>
        <Route path='/enterprise-portal/usage_component' element={<Usage/>}/>
        <Route path='/enterprise-portal/vocal_component' element={<Vocal/>}/>
        <Route path='/enterprise-portal/vocal_usage_component' element={<VocalUsage/>}/>
        <Route path='/enterprise-portal/usage' element={<img src={Image1} alt="Usage"/>}/>
        <Route path='/enterprise-portal/vocal-biomarker' element={<img src={Image2} alt="Vocal Biomarker"/>}/>
        <Route path='/enterprise-portal/vocal-biomarker-usage' element={<img src={Image3} alt="Vocal Biomarker Usage"/>}/>
      </Routes>

    </div>
  );
}

export default App;
