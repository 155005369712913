import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from './Header';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import backgroundImage from './images/sonde_logo.png'; 

const popupStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const popupContentStyle = {
    backgroundColor: '#00344E',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
    padding: '20px',
    textAlign: 'center',
    position: 'absolute',
    color: "#b2dfee",
    borderRadius: "15px",
    border: "1.5px solid #30A7FF",
};

const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
};

const Box = ({ text, link }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(link);
  };
 
  return (
    <div className="box" onClick={handleClick}>
      {text}
    </div>
  );
};

class Container extends React.Component {
    handleClick(imageUrl){
        window.open(imageUrl, '_blank')
    }
}

class AdminPortal extends React.Component {
  

    render() {
      const headerContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '10px'
      };
    
      const logoStyle = {
        width: '100px',
        height: 'auto'
      };
    
      const headerTextStyle = {
        flex: 1,
        textAlign: 'center',
        margin: 0
      };
    
      const rightPlaceholderStyle = {
        width: '50px' /* Same as logo width for alignment */
      };
        return (
<div >
<div style={headerContainerStyle}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={backgroundImage} alt="Logo" style={logoStyle} />
      </div>
      <h1 style={headerTextStyle}>Dashboard</h1>
      <div style={rightPlaceholderStyle}></div>
    </div>
<div className="container">
            <Box text="Population Usage Analysis" link="/enterprise-portal/usage_component" />
            <Box text="Population Vocal Bio-Marker Analysis" link="/enterprise-portal/vocal_component" />
            <Box text="User Vocal Bio-marker and Usage Analysis" link="/enterprise-portal/vocal_usage_component" />
          </div>
</div>

        )

    }
}

export default AdminPortal;
