import React, { Component } from 'react';
import RecordRTC from 'recordrtc';

class RecordAudio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRecording: false,
      audioBlob: null,
      recordRTC: null,
    };
  }

  componentDidMount() {
    this.initRecordRTC();
  }

  initRecordRTC = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/wav',
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
      });

      this.setState({ recordRTC: recorder });
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  startRecording = () => {
    const { recordRTC } = this.state;
    if (recordRTC) {
      this.setState({ audioBlob: null });
      recordRTC.startRecording();
      setTimeout(() => {
        this.stopRecording();
      }, 31000);
      this.setState({ isRecording: true });
    }
  };

  stopRecording = () => {
    const { recordRTC } = this.state;
    if (recordRTC) {
      recordRTC.stopRecording(() => {
        const blob = recordRTC.getBlob();
        this.setState({ audioBlob: blob, isRecording: false });
      });
    }
    console.log('Got Invoked Every 3 Seconds -  with blob - ', this.state.audioBlob)
  };

  saveRecording = () => {
    const { audioBlob } = this.state;
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = 'recordedAudio.wav';
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  render() {
    const { isRecording, audioBlob } = this.state;

    return (
      <div>
        <button onClick={this.startRecording} disabled={isRecording}>
          Start Recording
        </button>
        <button onClick={this.stopRecording} disabled={!isRecording}>
          Stop Recording
        </button>
        <button onClick={this.saveRecording} disabled={!audioBlob}>
          Save Recording
        </button>
        {audioBlob && (
          <div>
            <audio controls>
              <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
            </audio>
          </div>
        )}
      </div>
    );
  }
}

export default RecordAudio;
