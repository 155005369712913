import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from './Header';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { useState } from "react";
import Mar1 from './images/vocal-usage/BEYAMA/BEYAMA_mf_usage.png';
import Mar2 from './images/vocal/june/second.png';
import Mar3 from './images/vocal-usage/BEYAMA/BEYAMA_mf_week.png';
import Mar4 from './images/vocal-usage/BEYAMA/BEYAMA_scatterplot.png';

import April1 from './images/vocal-usage/LUTGRE/LUTGRE_mf_usage.png';
import April2 from './images/vocal/august/second.png';
import April3 from './images/vocal-usage/LUTGRE/LUTGRE_mf_week.png';
import April4 from './images/vocal-usage/LUTGRE/LUTGRE_scatterplot.png';
import backgroundImage from './images/sonde_logo.png'; 

import op1 from './images/vocal-usage/BEYAMA/sub_scores/BEYAMA_mf_score_and_all_subscores.png';
import op2 from './images/vocal-usage/BEYAMA/sub_scores/BEYAMA_mf_score_and_clarity.png';
import op3 from './images/vocal-usage/BEYAMA/sub_scores/BEYAMA_mf_score_and_control.png';
import op4 from './images/vocal-usage/BEYAMA/sub_scores/BEYAMA_mf_score_and_crispness.png';
import op5 from './images/vocal-usage/BEYAMA/sub_scores/BEYAMA_mf_score_and_energy_range.png';
import op6 from './images/vocal-usage/BEYAMA/sub_scores/BEYAMA_mf_score_and_liveliness.png';
import op7 from './images/vocal-usage/BEYAMA/sub_scores/BEYAMA_mf_score_and_pause_duration.png';
import op8 from './images/vocal-usage/BEYAMA/sub_scores/BEYAMA_mf_score_and_smoothness.png';
import op9 from './images/vocal-usage/BEYAMA/sub_scores/BEYAMA_mf_score_and_speech_rate.png';

import Lp1 from './images/vocal-usage/LUTGRE/sub_scores/LUTGRE_mf_score_and_all_subscores.png';
import Lp2 from './images/vocal-usage/LUTGRE/sub_scores/LUTGRE_mf_score_and_clarity.png';
import Lp3 from './images/vocal-usage/LUTGRE/sub_scores/LUTGRE_mf_score_and_control.png';
import Lp4 from './images/vocal-usage/LUTGRE/sub_scores/LUTGRE_mf_score_and_crispness.png';
import Lp5 from './images/vocal-usage/LUTGRE/sub_scores/LUTGRE_mf_score_and_energy_range.png';
import Lp6 from './images/vocal-usage/LUTGRE/sub_scores/LUTGRE_mf_score_and_liveliness.png';
import Lp7 from './images/vocal-usage/LUTGRE/sub_scores/LUTGRE_mf_score_and_pause_duration.png';
import Lp8 from './images/vocal-usage/LUTGRE/sub_scores/LUTGRE_mf_score_and_smoothness.png';
import Lp9 from './images/vocal-usage/LUTGRE/sub_scores/LUTGRE_mf_score_and_speech_rate.png';

const imagesByMonth = {
    BEYAMA: [Mar1, Mar2, Mar3, Mar4],
    LUTGRE: [April1, April2, April3, April4],
};

const secondaryImagesByMonth = {
    BEYAMA: {
        All_Sub_Scores: op1,
        Clarity: op2,
        Control: op3,
        Crispness: op4,
        Energy_Range: op5,
        Liveliness: op6,
        Pause_Duration: op7,
        Smoothness: op8,
        Speech_Rate: op9
    },
    LUTGRE: {
        All_Sub_Scores: Lp1,
        Clarity: Lp2,
        Control: Lp3,
        Crispness: Lp4,
        Energy_Range: Lp5,
        Liveliness: Lp6,
        Pause_Duration: Lp7,
        Smoothness: Lp8,
        Speech_Rate: Lp9
    }

};

const Box = ({ text, link, classN }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(link);
    };

    return (
        <div className={classN} onClick={handleClick} style={{ padding: '10px', border: '1px solid black', borderRadius: '5px' }}>
            {text}
        </div>
    );
};

class VocalUsage extends React.Component {
    containerStyle = {
        padding: '20px',
        marginLeft: '10px',
    };

    monthSummaryStyle = {
        marginBottom: '15px',
        marginTop: '10px',
    };

    gridStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px',
    };

    imageStyle = {
        width: '100%',
        height: '100%',
    };
    imageStyle3 = {
        width: '80%',
        height: '45%',
        marginLeft: '-25%'
    };

    headerStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'

    };

    rightContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '38%', // Adjust this width as needed
    };

    constructor(props) {
        super(props);
        const defaultMonth = 'BEYAMA'; // Set the default month here
        const defaultOption = 'All_Sub_Scores'
        this.state = {
            selectedMonth: defaultMonth,
            selectedSecondaryOption: 'All_Sub_Scores',
            images: imagesByMonth[defaultMonth] || [],
            secondaryImages: secondaryImagesByMonth[defaultMonth][defaultOption],
        };
    }

    handleMonthChange = (event) => {
        const month = event.target.value;
        const defaultOption = 'All_Sub_Scores'; // Reset the secondary dropdown to default
        this.setState({
            selectedMonth: month,
            selectedSecondaryOption: defaultOption,
            images: imagesByMonth[month] || [],
            secondaryImages: secondaryImagesByMonth[month][defaultOption],
        });
    };

    handleSecondaryOptionChange = (event) => {
        const option = event.target.value;
        this.setState({
            selectedSecondaryOption: option,
            secondaryImages: secondaryImagesByMonth[this.state.selectedMonth][option],
        });
    };

    render() {
        const dropdownContainerStyle = {
            width: '50%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
            marginLeft: '0%'
        };
        
        const dropdownContainerStyleFeature = {
            
            width: '50%',
            marginBottom: '10px',
            
        };
        

        // const dropdownStyle = {
        //     padding: '10px',
        //     fontSize: '15px',
        //     backgroundColor: '#f8f9fa', // Light background color
        //     border: '1px solid #ced4da',
        //     borderRadius: '5px',
        //     outline: 'none',
        //     cursor: 'pointer',
        //     transition: 'background-color 0.3s ease, transform 0.3s ease',
        // };
        const dropdownStyleFeature = {
            marginLeft: '600px',
            padding: '10px',
            fontSize: '15px',
            backgroundColor: '#f8f9fa', // Light background color
            border: '1px solid #ced4da',
            borderRadius: '5px',
            outline: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
            marginTop: '10px'
        };
        // const dropdownLabelStyle = {
        //     fontSize: '15px',
        //     fontWeight: 'bold',
        //     color: '#333',
        // };



        // const dropdownContainerStyle = {
        //     display: 'flex',
        //     alignItems: 'center',
        //     marginBottom: '20px',
        // };
        
        const dropdownItemStyle = {
            display: 'flex',
            alignItems: 'center',
            marginRight: '20px',
            
        };
        
        const dropdownLabelStyle = {
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#333',
            marginLeft: '10px',
        };
        
        const dropdownStyle = {
            height: '25px',
            margin: '2px',
            fontSize: '13px',
            backgroundColor: '#f8f9fa',
            border: '1px solid #ced4da',
            borderRadius: '5px',
            outline: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
        };


        return (
            <div>
                

                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <div style={{ ...this.headerStyle, flex: '1' }}>
                        <img src={backgroundImage} alt="Logo" style={{ width: '100px', height: '50px', margin: '10px' }} />
                        <Box text="User Vocal Bio-marker and Usage Analysis" classN="box_usage" />
                    </div>
                    <div style={{ ...this.rightContainerStyle, marginLeft: 'auto' }}>
                        <Box text="Usage Analysis" link="/enterprise-portal/usage_component" classN="box_usage_1" />
                        <Box text="Pop. Analysis" link="/enterprise-portal/vocal_component" classN="box_usage_1" />
                        <Box text="User Analysis" link="/enterprise-portal/vocal_usage_component" classN="box_usage_1" />
                    </div>
                </div>

                
                <div style={dropdownContainerStyle}>
    <div style={dropdownItemStyle}>
        <label htmlFor="month" style={dropdownLabelStyle}>User Name: </label>
        <select
            id="month"
            value={this.state.selectedMonth}
            onChange={this.handleMonthChange}
            style={dropdownStyle}
        >
            <option value="BEYAMA">BEYAMA</option>
            <option value="LUTGRE">LUTGRE</option>
        </select>
    </div>
    <div style={dropdownItemStyle}>
        <label htmlFor="secondaryOption" style={dropdownLabelStyle}>Select Feature: </label>
        <select
            id="secondaryOption"
            value={this.state.selectedSecondaryOption}
            onChange={this.handleSecondaryOptionChange}
            style={dropdownStyle}
        >
            <option value="All_Sub_Scores">All Sub Scores</option>
            <option value="Clarity">Clarity</option>
            <option value="Control">Control</option>
            <option value="Crispness">Crispness</option>
            <option value="Energy_Range">Energy Range</option>
            <option value="Liveliness">Liveliness</option>
            <option value="Pause_Duration">Pause Duration</option>
            <option value="Smoothness">Smoothness</option>
            <option value="Speech_Rate">Speech Rate</option>
        </select>
    </div>
</div>
               

                <div style={this.containerStyle}>
                    <div style={this.gridStyle}>
                        <div>
                            <img src={this.state.images[0]} alt={`Image for ${this.state.selectedMonth} 1`} style={this.imageStyle} />
                        </div>


                        <img src={this.state.secondaryImages} alt={`Secondary Image for ${this.state.selectedSecondaryOption}`} style={this.imageStyle} />


                        <div>
                            <img src={this.state.images[2]} alt={`Image for ${this.state.selectedMonth} 3`} style={this.imageStyle3} />
                        </div>
                        <div>
                            <img src={this.state.images[3]} alt={`Image for ${this.state.selectedMonth} 4`} style={this.imageStyle3} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VocalUsage;
