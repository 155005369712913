import React from 'react';
import './DashPortalHeader.css';
import backgroundImage from './images/sonde_logo_blue_bg.jpg'; 


const DashPortalHeader = () => {
    
  return (
    <div className="header">
      <div className="logo">
      <img src={backgroundImage} alt="Logo" style={{ width: '100px', height: '50px'}} />
      </div>
      <div className="menu">
        <button className="menu-button active">Population Usage Analysis</button>
        <button className="menu-button">Population Summary</button>
        <button className="menu-button">User Analysis</button>
        <button className="menu-button">Usage Analysis</button>
      </div>
    </div>
  );
};

export default DashPortalHeader;
