import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from './Header';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { useState } from "react";
import Mar1 from './images/vocal/june/first.png';
import Mar2 from './images/vocal/june/second.png';
import Mar3 from './images/vocal/june/third.png';
import Mar4 from './images/vocal/june/fourth.png';
import Mar5 from './images/vocal/june/sixth.png';
import Mar6 from './images/vocal/june/seven.png';

import April1 from './images/vocal/august/first.png';
import April2 from './images/vocal/august/second.png';
import April3 from './images/vocal/august/third.png';
import April4 from './images/vocal/august/fourth.png';
import April5 from './images/vocal/august/sixth.png';
import April6 from './images/vocal/august/seven.png';

import backgroundImage from './images/sonde_logo.png'; 

const imagesByMonth = {
    June: [
        Mar1, Mar2, Mar3, Mar4, Mar5, Mar6
    ],
    August: [
        April1, April2, April3, April4, April5, April6
    ],
};

const Box = ({ text, link, classN }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(link);
    };

    return (
        <div className={classN} onClick={handleClick} style={{ padding: '10px', border: '1px solid black', borderRadius: '5px' }}>
            {text}
        </div>
    );
};

class Vocal extends React.Component {
    containerStyle = {
        padding: '20px',
        marginLeft: '10px'
    };

    monthSummaryStyle = {
        marginBottom: '20px',
        marginTop: '20px',
    };

    gridStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px',
    };

    topImageStyle = {
        gridColumn: 'span 2',
    };

    imageStyle = {
        width: '100%',
        height: 'auto',
    };

    headerStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'

    };

    rightContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '38%', // Adjust this width as needed
    };

    constructor(props) {
        super(props);
        const defaultMonth = 'June'; // Set the default month here
        this.state = {
            selectedMonth: defaultMonth,
            images: imagesByMonth[defaultMonth] || [],
        };
    }

    handleMonthChange = (event) => {
        const month = event.target.value;
        this.setState({
            selectedMonth: month,
            images: imagesByMonth[month] || [],
        });
    };

    render() {
        const dropdownContainerStyle = {
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
        };
        
        const dropdownStyle = {
            height: '25px',
            margin: '2px',
            fontSize: '13px',
            backgroundColor: '#f8f9fa',
            border: '1px solid #ced4da',
            borderRadius: '5px',
            outline: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
        };
        
        const dropdownLabelStyle = {
            marginRight: '10px',
            fontSize: '15px',
            fontWeight: 'bold',
            color: '#333',
        };
        

        return (
            <div>
                


                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <div style={{ ...this.headerStyle, flex: '1' }}>
                        <img src={backgroundImage} alt="Logo" style={{ width: '100px', height: '50px', margin: '10px'}} />
                        <Box text="Population Vocal Bio-Marker Analysis" classN="box_usage" />
                    </div>
                    <div style={{ ...this.rightContainerStyle, marginLeft: 'auto' }}>
                        <Box text="Usage Analysis" link="/enterprise-portal/usage_component" classN="box_usage_1" />
                        <Box text="Pop. Analysis" link="/enterprise-portal/vocal_component" classN="box_usage_1" />
                        <Box text="User Analysis" link="/enterprise-portal/vocal_usage_component" classN="box_usage_1" />
                    </div>
                </div>

                <div style={dropdownContainerStyle}>
                    <div style={this.monthSummaryStyle}>
                        <label htmlFor="month" style={dropdownLabelStyle}>Month Summary: </label>
                        <select 
                            id="month" 
                            value={this.state.selectedMonth} 
                            onChange={this.handleMonthChange} 
                            style={dropdownStyle}
                        >
                            <option value="June">June 1-14</option>
                            <option value="August">August 1-14</option>
                            {/* Add more options for other months */}
                        </select>
                    </div>
                </div>

                <div style={this.containerStyle}>
                    <div style={this.gridStyle}>
                        {this.state.images.map((image, index) => (
                            <div key={index} style={{ gridColumn: index < 2 ? 'span 1' : 'span 1' }}>
                                <img src={image} alt={`Image for ${this.state.selectedMonth} ${index + 1}`} style={this.imageStyle} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Vocal;
